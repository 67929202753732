
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import BankPaymentData from "@/data/payment";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { saveFile } from "@/core/services/FileSaverService";

export default defineComponent({
  name: "BanksPayment",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Төлбөрийн мэдээлэл");
    });
  },
  data() {
    return {
      loadingTable: true,
      downloadInvoice: false,
      monthValue: "",
      months: BankPaymentData[0]["months"],
      yearValue: "",
      years: BankPaymentData[0]["year"],
      tableData: []
    };
  },
  mounted() {
    this.getBankPaymentsInvoice();
  },
  methods: {
    async getInvoice(invoicenumber) {
      this.downloadInvoice = true;
      const params = {
        invoicenumber: invoicenumber
      };
      await ApiService.post(`banksinvoice`, {
        params
      })
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            saveFile(
              results.response,
              invoicenumber,
              "application/pdf",
              ".pdf"
            );
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Нэхэмжлэл татахад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.downloadInvoice = false;
        });
    },
    async getBankPaymentsInvoice() {
      this.loadingTable = true;
      await ApiService.get(`banksinvoice`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            this.tableData = results.response;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    }
  }
});
